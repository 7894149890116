import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';

const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);

let dbBuffer:any = [] ;
let dbBufferError:any = [] ;
let dbBufferBusy = false;
let dbBufferIn = 0;
let dbBufferOut = 0;
let sqliteFailure = false;

export function dbQuery(qry:string,target?:Function){
  if( sqliteFailure ) {
    dbBufferBusy = false ;
    sqliteFailure = false ;
  }
  dbBufferIn++
  dbBuffer.push([qry,target])
  dbBufferWorker()
}

export async function dbBufferWorker(){
  if(dbBufferBusy===false){
    if(dbBuffer.length>0){
      dbBufferBusy=true
      try {
        const db: SQLiteDBConnection = await sqlite.createConnection('saint_menue', false, 'no-encryption', 1);
        const isDb = (await db.isExists()).result;
        if ( ! isDb ) {
          console.error('DBInit: DB nicht connected');
        }
        await db.open();
        const isDbOpen = (await db.isDBOpen()).result;
        if ( ! isDbOpen ) {
          console.error('DBInit: DB nicht open');
        }
        while(dbBuffer.length>0){
          let myChanges = await db.query( dbBuffer[0][0] );
          if(typeof dbBuffer[0][1] === 'function'){
            console.log("dbQueryResult",myChanges)
            dbBuffer[0][1](myChanges)
          }
          dbBuffer.shift()
          dbBufferOut++
        }
        await db.close();
      } 
      catch (err) {
        sqliteFailure = true ;
        dbBufferError
          .push(
            [
              new Date().toLocaleString() ,
              err ,
              dbBuffer.shift()
            ]
          )
        console.error( 'Fehler dbBufferWorker: ', dbBufferError[ dbBufferError.length - 1 ] );
        console.info( 'Fehlerliste dbBufferWorker: ', dbBufferError );
        throw new Error(`Error: ${err}`);
      }
      finally {
        if( ! sqliteFailure ) {
          dbBufferBusy=false
          dbBufferWorker()
        }
      }
    }   
  }
}

export function exportDbBufferIn( action?:string ){
  if( action === 'reset' ) {
    dbBufferIn = 0 ;
  }
  return dbBufferIn
}

export function exportDbBufferOut( action?:string ){
  if( action === 'reset' ) {
    dbBufferOut = 0 ;
  }
  return dbBufferOut
}
