import { FunctionComponent, useContext , useEffect, useState } from "react";
import { Storage } from "@capacitor/storage";
import { DataContext } from '../../context/DataContext'

type RezeptZusatzstoffe_Props = {
  rezepte_zusatzstoffe: any
}

export const RezeptZusatzstoffe: FunctionComponent<RezeptZusatzstoffe_Props> = ( { rezepte_zusatzstoffe } ) => {

  const { appZusatzstoffe } = useContext( DataContext ) ;

  return(
    <>
      {
        Array.isArray( rezepte_zusatzstoffe )
          ?
            <span style = { { color: 'red' , display: 'inline' , fontSize: '0.7em' , verticalAlign: 'super' } }>{
              appZusatzstoffe
                .filter(
                  ( entry:any ) => rezepte_zusatzstoffe.includes( entry.zusatzstoff_id )
                )
                .map(
                  ( entry:any ) => entry.zusatzstoffe_anzeige + ') ' + entry.zusatzstoff_bezeichnung
                )
                .sort()
                .join( ', ' )
            }</span>
          :
            ''
      }
    </>
  )
}

