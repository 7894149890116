import { IonCard, IonCardContent, IonItem, IonToast,IonSegment, IonSegmentButton, IonInput, IonButton,IonLabel, IonCardHeader, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';
import { useHistory } from 'react-router-dom';
import axios from "axios";


import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function Welcome() {

 // console.log("lade Welcome")
  const {
    appServer,
    appServerChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
  } = useContext(DataContext);

const [segment, setSegment] = useState('login')
const [loginUser, setLoginUser] = useState('');
const [domain, setDomain] = useState('solufind');
const [regPass, setRegPass] = useState('');
const [toast, setToast] = useState(false);



const [loginPass, setLoginPass] = useState('');
const [loginTry, setLoginTry] = useState(false);

const history = useHistory();

useEffect( () => {
    if (loginTry === true)
    {
            fetchLogin();
    }
    else setLoginTry(false)

}, [loginTry])

const register = async () => { 
  Storage.set({
    key: 'server',
    value: appServer
  })

}

const getMittag = async () => {

  // console.log("hole daten");
  var raw = {};
  var myHeaders = new Headers();
  myHeaders.append(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  await fetch(appServer + "/funktionen/api2/senioren/mittag.php", requestOptions)
  .then((response) => response.json())
  .then(async (result) => {
    // console.log("middach", result);
    let storagedata = {
      key: "mittag",
      value: JSON.stringify(result),
    };
    // // console.log("mittag", storagedata);
    await Storage.set(storagedata);
  });
}

const getAbend = async (requestOptions) => {
  
  // console.log("hole daten");
  var raw = {};
  var myHeaders = new Headers();
  myHeaders.append(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
  };
  await fetch(appServer + "/funktionen/api2/senioren/abend.php", requestOptions)
  .then((response) => response.json())
  .then(async (result) => {
    // console.log("result", result);
    let storagedata = {
      key: "abend",
      value: JSON.stringify(result),
    };
    // console.log("abend", storagedata);
    await Storage.set(storagedata);
  });
}

const getFrueh = async (requestOptions) => {
  
  // console.log("hole daten");
  var raw = {};
  var myHeaders = new Headers();
  myHeaders.append(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
  };

  await fetch(appServer + "/funktionen/api2/senioren/frueh.php", requestOptions)
    .then((response) => response.json())
    .then(async (result) => {
      // console.log("result", result);
      let storagedata = {
        key: "frueh",
        value: JSON.stringify(result),
      };
      // console.log("frueh", storagedata);
      await Storage.set(storagedata);
    });

}

const getBewohner = async (requestOptions) => {
  
  var raw = {};
  var myHeaders = new Headers();
  myHeaders.append(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
  };
  await fetch(appServer + "/funktionen/api2/senioren/bewohner.php", requestOptions)
  .then((response) => response.json())
  .then(async (result) => {
    // console.log("result", result);
    let storagedata = {
      key: "bewohner",
      value: JSON.stringify(result),
    };
    // console.log("bewohner", storagedata);
    await Storage.set(storagedata);
  });

}

const getData = async () => {

  
  await getFrueh();  
  await getMittag();
  await getAbend();
  await getBewohner();
  
  
};


const fetchLogin = () => {
  //var axios = require("axios");
    var data = {
      usr: loginUser,
      pwd: loginPass,
      domain: domain,
    };

    var config = {
      method: "post",
      url: appServer+"/funktionen/api2/user/login.php",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async function (response) {
        // console.log(JSON.stringify(response.data));

        if (response.data) {
          let storagedata = {
            key: "token",
            value: response.data.token,
          };
          // console.log("storagedata", storagedata);
           Storage.set(storagedata);
          
   if (response.data.status === 200)
        { 
        
          appLoggedInChange(true)
          await getData()
          window.location.href = "/home"
        }
        
        // window.location.reload(false);
        //window.location.href = "/home";
        
      }})
      .finally(function (){

        setToast(false)

       

        

      })
    

      .catch(function (error) {
        if (error.response.data.status === 401 )
        {
            setToast(true);
            setLoginTry(false)
        }
        // console.log(error.response.data.status)
        // console.log("err", error);
      });

    
};


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
       

        <IonCard>     
    {/* <IonCardContent>            
            
            Bitte nutzen Sie das nachfolgende Formular zur Anmeldung. Geben Sie dafür bitte ihren Benutzernamen und das Passwort ein. Beide Informationen wurden Ihnen vorab per eMail bereitgestellt.
            
            </IonCardContent> */}

        </IonCard>
        <IonCard>
        <IonCardHeader>
    </IonCardHeader>
    <IonCardContent>
            
          
            
            
            <IonSegment value={segment} onClick={e => setSegment(e.target.value)}>
          <IonSegmentButton value="login">
            <IonLabel>Login</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="register">
            <IonLabel>Einstellungen</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        { (segment && (segment === 'login')) ? <><IonItem>         
            <IonLabel position="floating">
                Benutzername
            </IonLabel>
            <IonInput placeholder="Name" onIonChange={e=> setLoginUser(e.target.value)} value={loginUser}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                Passwort
            </IonLabel>
            <IonInput placeholder="Passwort"  type="password" onIonChange={e=> setLoginPass(e.target.value)} value={loginPass}></IonInput>
            </IonItem>
            <IonButton expand="full" onClick={()=> setLoginTry(true)}>Anmelden</IonButton>
            </> : 
             (segment && (segment === 'register')) ?
            <>
            <IonItem>         
            <IonLabel position="floating">
                Server
            </IonLabel>
            <IonInput placeholder="Server"  onIonChange={e=> appServerChange(e.target.value)} value={appServer}></IonInput>
            </IonItem>

            <IonButton expand="full" onClick={ () => register() }>speichern</IonButton>
            </>
            : ''
             }
<IonItem>
  <IonLabel>
    Version 1.04.002.3532 
  </IonLabel>
  
</IonItem>
            </IonCardContent>

        </IonCard>

        <IonToast
        isOpen={toast}
        color="danger"
        onDidDismiss={() => setToast(false)}
        message="Benutzerdaten falsch. Bitte prüfen."
        duration={2000}
      />


       
      </IonContent>
    </IonPage>
  );
};
