import { IonCard, IonList, IonItem, IonLabel, IonButton, IonIcon, IonCardHeader, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import { useParams } from 'react-router';
import axios from 'axios';

import { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';
import { useHistory } from 'react-router-dom';


import { useContext } from 'react';
import {DataContext} from '../context/DataContext'




export default function Order(props) {
  const [bewohner, setBewohner] = useState()
  const { stationsid } = useParams();
  const { stationsbezeichnung } = useParams();
  const history = useHistory();
  const {appBewohnerSollIdChange,appBewohnerSollBezeichnungChange,appSollReloadAuswahlChange} = useContext(DataContext);


  const [bewohnerliste, setBewohnerliste] = useState(
<IonItem ><IonLabel>Lade Bewohner</IonLabel></IonItem>

  )


const {
  appStation,appStationBezeichnung
} = useContext(DataContext);

function bewohnerlisteErstellen(){
  
  return(
      bewohner.map((elementInArray, index) => {
    // console.log('betriebe_bewohner_wb',elementInArray.betriebe_bewohner_wb)
    // console.log('stationsid',appStation)
    if(elementInArray.betriebe_bewohner_wb===appStation){
      return (        
      <IonItem button 
      routerLink={"/woche/"+appStation+"/"+appStationBezeichnung+"/"+elementInArray.betriebe_bewohner_id+'/'+elementInArray.betriebe_bewohner_name}
      onClick =
                      {
                        () =>
                          {

                            appBewohnerSollIdChange(elementInArray.betriebe_bewohner_id);
                            appBewohnerSollBezeichnungChange(elementInArray.betriebe_bewohner_name)
                            appSollReloadAuswahlChange(true);
                          }
                      }
      >
        <IonLabel>{elementInArray.betriebe_bewohner_name} ({elementInArray.betriebe_bewohner_raum})</IonLabel></IonItem>
      )

      
      }
}
))
}
  

  useEffect(() => {
    // console.log('hole bewohner')
    const checkBewohner = async () => {
      const { value } = await Storage.get({ key: 'bewohner' })
      setBewohner(JSON.parse(value))
    
    }
    checkBewohner()
    
  }, []);

  useEffect(() => {
    bewohner && setBewohnerliste(bewohnerlisteErstellen()) 
    // console.log(bewohnerliste)
  }, [bewohner, appStation]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appStationBezeichnung}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">{appStationBezeichnung}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton routerLink={'/stationen/x/x'}>zurück</IonButton>

        <IonList>
        {bewohnerliste}
          
  

    </IonList>
      </IonContent>
    </IonPage>
  );
};
