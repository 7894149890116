import { IonCard, IonButton,IonInput, IonNote,IonItem,IonGrid,IonRow,IonCol,IonCardHeader, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCheckbox, IonCardContent, IonCardSubtitle, IonImg } from '@ionic/react';
import './Qr.css';
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Storage } from '@capacitor/storage';
import { BewohnerZusatzstoffe } from '../components/_funktionen/bewohner_zusatzstoffe';
import { RezeptZusatzstoffe } from '../components/_funktionen/rezept_zusatzstoffe';
import { Servierhinweise } from '../components/_funktionen/servierhinweise' ;

import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function Fruehstueck(props) {
  const { bewohnerid } = useParams();
  const { bewohnerbezeichnung,stationsid, stationsbezeichnung } = useParams();
  const { woche } = useParams();
  const { datumsql } = useParams();
  const { mahlzeit } = useParams();


  const {
    appBewohnerZusatzstoffe ,
    appServer,
    appServerChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
  } = useContext(DataContext);




  var localdate = new Date(datumsql);

  const [token, setToken] = useState()
  const [regServer, setRegServer] = useState(appServer);
  const [komponenten, setKomponenten] = useState([])
  const [gewaehlt, setGewaehlt] = useState();

  const [ bewohnerBemerkung , setBewohnerBemerkung ] = useState(  ) ;


  const checkBewohner = async () => {
    // console.log('checkBewohner',checkBewohner)
    const { value } = await Storage.get( { key: 'bewohner' } ) ;
    // @ts-ignore
    let myObj = JSON.parse( value ) ;
    for( const[ key , value ] of Object.entries( myObj ) ) {
      // @ts-ignore
      if( value.betriebe_bewohner_id === bewohnerid ) {
        // @ts-ignore
        setBewohnerBemerkung( value.bewohner_bemerkung+" "+value.bewohner_sonderkost ) ;

      }
    }                
  }

  useEffect( () => {


    const checkToken = async () => {
      const { value } = await Storage.get({ key: 'token' })
      if (!value) { // console.log("checktoken", value); 
        return null }
      else return value;
    }
  
    
  
    checkToken()
    .then(result => { 
      // // console.log("checktokenresult", result)
      
      const checkServer = async () => {
        const { value } = await Storage.get({ key: 'server' })
        if (!value) { // console.log("server", value); 
          return null }
        else return value;
      
      }
    checkServer()
    .then(result=>{
      // console.log('server', result)
      if (result !== null) {
  
        setRegServer(result)
        
  
      }
    })
    
    
  })
  // console.log('hole speisen')
  const checkAuswahl = async () => {
    let essen = 'frueh';
    
    const { value } = await Storage.get({ key: essen })
    setAuswahl(JSON.parse(value))
  
  }
  checkAuswahl()

  // console.log('hole gewaehlte')
  const checkGewaehlt = async () => {
    const { value } = await Storage.get({ key: 'auswahl' })
    setGewaehlt(JSON.parse(value))
  
  }
  checkGewaehlt()
  checkBewohner()
},[])

  

  function SendData(bewohner_id, rezept, menge, zeit, datum){
    // console.log('regServer',regServer)    
    // console.log('bewohner_id',bewohner_id)    
    // console.log('rezept',rezept)    
    // console.log('menge',menge)    
    // console.log('zeit',zeit)    
    // console.log('datum',datum)    

    const fetchData = {
      token: token,
      //BetriebeId: BetriebeId,
    };
    const api = axios.create({
      baseURL: appServer+'/funktionen/api2/senioren/rezept_buchen.php?bewohner_id='+bewohner_id+'&rezept='+rezept+'&menge='+menge+'&zeit='+zeit+'&datum='+datumsql,
    });
    api.post("", fetchData)
      .then((res) => {
        
          let data = res.data;
          // console.log('daten',data)
          
          

})



}


  let json=[
    {
    "speiseplan_id": "552787",
    "wieder_speiseplan_id": "419",
    "speiseplan_tag": "2022-05-19",
    "speiseplan_kategorie": "1",
    "speiseplan_rezepte_id": "3249",
    "speiseplan_forecast": "0",
    "speiseplan_produktion": "0",
    "speiseplan_ist": "0",
    "speiseplan_text": "Kaffee",
    "speiseplan_status": null,
    "speiseplan_betriebe_id": "2",
    "speiseplan_plan_id": "2",
    "speiseplan_rezept_piktogramm": "0",
    "speiseplan_preis": "",
    "speiseplan_optik": null,
    "speiseplan_geschmack": null,
    "speiseplan_konsistenz": null,
    "speiseplan_geruch": null,
    "speiseplan_temperatur": null,
    "speiseplan_sensorik_von": null,
    "speiseplan_sensorik_bemerkung": null,
    "rezepte_id": "3249",
    "rezepte_bezeichnung": "Kaffee",
    "rezepte_anweisung": "        ",
    "rezepte_rezeptgruppen": "24",
    "rezepte_kellen_id": "1",
    "rezepte_interner_preis": "",
    "rezepte_vk_preis": "",
    "rezepte_status": "1",
    "rezepte_freigabe": "Torsten Kempf",
    "rezepte_datum": "2022-04-20 06:59:02",
    "rezepte_ursprung": "2795",
    "rezepte_arten": "1",
    "rezepte_garverlust": "0",
    "rezepte_anmerkung_ausgabe": "",
    "rezepte_bild": null,
    "rezepte_bestelleinheit": "Portion",
    "rezepte_bestelleinheit2": "",
    "rezepte_bestelleinheit_faktor2": "0.00",
    "rezepte_bestelleinheit3": "",
    "rezepte_bestelleinheit_faktor3": "0.00",
    "rezepte_bestelleinheit4": "",
    "rezepte_bestelleinheit_faktor4": "0.00",
    "rezepte_steuer_id": "1",
    "rezepte_verkaufsbezeichnung": "Kaffee",
    "betriebe_speiseplaene_id": "2",
    "betriebe_speiseplaene_bezeichnung": "Frühstück WSD",
    "betriebe_speiseplaene_betriebe_id": "2",
    "betriebe_speiseplaene_typ": "2",
    "betriebe_speiseplaene_zeit": "1",
    "betriebe_speiseplaene_komponenten": "0",
    "betriebe_speisepläne_vers": "1"
    }
    ]
    const [auswahl, setAuswahl] = useState(json)

  function speiseplan(){
    // console.log(auswahl)
    let alt_komponenten=komponenten
return (
  
  auswahl.map((elementInArray, index) => {

     
      // console.log('aufruf artikelfunktion',elementInArray)
      
       return artikel(elementInArray, index)
       

} 
))
  }
  const [speiseplanreturn, setSpeiseplanreturn] = useState(speiseplan())


  function artikel(elementInArray, index){
    //// console.log(elementInArray)
    let disable = false ;
    if( Array.isArray( elementInArray.rezepte_zusatzstoffe ) ) {
      if( elementInArray.rezepte_zusatzstoffe.findIndex( ( entry ) => { return appBewohnerZusatzstoffe.includes( entry ) } ) > -1 ) {
        disable = true ;
      }
    }
    return(
    <> 
    <IonCol size-xs={6} size-sm={6} size-md={4} size-lg={4} size-xl={4}>
    <IonCard>
    <IonCardHeader>
                <IonCardTitle>
                  {elementInArray.rezepte_bezeichnung} <RezeptZusatzstoffe rezepte_zusatzstoffe = {elementInArray.rezepte_zusatzstoffe} />
                </IonCardTitle>
                
                
                  
                  
                
              </IonCardHeader>
              <IonCardContent>
              {
                disable
                  ?
                    <div
                      style = { { height: '5em' , textAlign: 'center' } }
                    >
                      <i>nicht auswählbar wegen bekannter Unverträglichkeit</i>
                    </div>
                  :
                    <>
                      <div style={{display:'flex', justifyContent: 'space-between'}}>
                        <IonNote slot="start" > <IonButton size="large" onClick={()=>addKomponente(elementInArray.speiseplan_rezepte_id,index,komponenten[elementInArray.speiseplan_rezepte_id] ? komponenten[elementInArray.speiseplan_rezepte_id]-1:0 )}>-</IonButton></IonNote>
                        <IonInput disabled type="text" style={{textAlign: 'center'}} size="2" slot="end" value={komponenten[elementInArray.speiseplan_rezepte_id] ? komponenten[elementInArray.speiseplan_rezepte_id] :0 }></IonInput>
                       { (komponenten[elementInArray.speiseplan_rezepte_id]*1)!=4 ?
                        <IonNote slot="end">  <IonButton size="large"  onClick={()=>addKomponente(elementInArray.speiseplan_rezepte_id,index,komponenten[elementInArray.speiseplan_rezepte_id] != null ? (komponenten[elementInArray.speiseplan_rezepte_id]*1)+1:1)}>+</IonButton></IonNote>
                      : ""}
                      </div>
                    </>
              }
              <IonImg 
          src={
            elementInArray.rezepte_bild && elementInArray.rezepte_bild.split('-')[0] ? appServer+"/bilder/ofw/rezepte/"+elementInArray.rezepte_bild : appServer+'/bilder/kein_bild.png'
          }
          onClick = { 
            disable 
              ?
                ''
              :
                ()=>addKomponente(elementInArray.speiseplan_rezepte_id,index,komponenten[elementInArray.speiseplan_rezepte_id] != null ? komponenten[elementInArray.speiseplan_rezepte_id]+1:0)
          }
          style = { { opacity: disable ? '0.3' : '1.0' } }
          ></IonImg>
    
    
              </IonCardContent>
    
              </IonCard></IonCol>
    </>)}

  function addKomponente(rezept,index, newvalue){
    let alt_komponenten=komponenten
    alt_komponenten[rezept]=newvalue
    setKomponenten(alt_komponenten)
    // console.log('alt_komponenten',alt_komponenten)
    // console.log('komponenten',komponenten)

    SendData(bewohnerid,rezept,newvalue, 1, datumsql)
    setSpeiseplanreturn(speiseplan()) 
  }
  

 
  
  useEffect(() => {
    // console.log('aktualisiere')
    let alt_komponenten=komponenten;
    gewaehlt && gewaehlt.map((gewaehltInArray, newindex) => {
      //// console.log('elementInArray.rezepte_id',elementInArray.rezepte_id)
      //// console.log('gewaehltInArray.bewohner_verzehr_rezept',gewaehltInArray.bewohner_verzehr_rezept)
      if(gewaehltInArray.bewohner_verzehr_zeit === "1" ){
        // console.log('gewaehltInArray.bewohner_verzehr_menge',gewaehltInArray.bewohner_verzehr_menge)

          alt_komponenten[gewaehltInArray.rezepte_id]=gewaehltInArray.bewohner_verzehr_menge
          // console.log('alt_komponenten',alt_komponenten)
        }
        
       
      }


    
    )
    setKomponenten(alt_komponenten)
    // console.log('gesetzte alt_komponenten',alt_komponenten)
    setSpeiseplanreturn(speiseplan())



  }, [gewaehlt]);
  


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Frühstück {bewohnerbezeichnung} - {localdate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Frühstück {bewohnerbezeichnung} - {localdate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</IonTitle>
            
          </IonToolbar>
        </IonHeader>
        <IonButton routerLink={'/woche/'+stationsid+'/'+stationsbezeichnung+'/'+bewohnerid+'/'+bewohnerbezeichnung}>speichern & zurück</IonButton>
        {woche == '0' ? <IonItem>Es kann nicht mehr bestellt werden</IonItem>: '' }
        <IonItem>
          <Servierhinweise bewohnerid = { bewohnerid } zeit = "Früh" />
          <BewohnerZusatzstoffe bewohnerid = { bewohnerid } />
          {bewohnerBemerkung && bewohnerBemerkung}
        </IonItem>
        <IonGrid>
          <IonRow>
           
        {json && speiseplanreturn}
        
        
        </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
