import {
  IonCard,
  IonIcon,
  IonTitle,
  IonToast,
  IonFooter,
  IonItem,
  IonLabel,
  IonMenu,
  IonToolbar,
  setupIonicReact,
} from "@ionic/react";
import { IonContent } from "@ionic/react";
import { useEffect, useState } from "react";

import infoIcon from "./assets/information-circle.svg";

import homeIcon from "./assets/home.svg";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";


import informationIcon from "./assets/information-circle.svg";
import nutritionIcon from "./assets/nutrition.svg";
import readerIcon from "./assets/reader.svg";
import returnIcon from "./assets/return.svg";

import { Storage } from "@capacitor/storage";


import { useContext } from "react";
import { DataContext } from "./context/DataContext";

//import cfm from './assets/cfm.png'

setupIonicReact();

export default function SidebarLeft() {
  const [toast, setToast] = useState(false);
  const [difference, setDifference] = useState(null);
  const version = "1.0.2";

  const {
    appServer,
    appServerChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
  } = useContext(DataContext);

  const logout = async () => {
    await Storage.remove({ key: "abend" });
    await Storage.remove({ key: "auswahl" });
    await Storage.remove({ key: "bewohner" });
    await Storage.remove({ key: "frueh" });
    await Storage.remove({ key: "mittag" });
    window.localStorage.removeItem( "bewohner" );
    window.localStorage.removeItem( "haeuser" );
    window.localStorage.removeItem( "rezepte" );
    window.localStorage.removeItem( "server" );
    window.localStorage.removeItem( "speiseplan" );
    window.localStorage.removeItem( "stationen" );
    window.localStorage.removeItem( "token" );
    appLoggedInChange(false)
  };

  useEffect(() => {
  }, []);

  return (
    <IonMenu side="start" contentId="main" type="overlay">
      <IonContent>
        <IonItem color="secondary"></IonItem>
        <IonItem lines="none">
          <IonCard id="cfmcard">
            <img
              src="https://saint-online.de/wp-content/uploads/2019/10/saint-logo20190212-1.png"
              alt="CFM LOGO"
              id="cfm"
            />
          </IonCard>
        </IonItem>
        <IonItem button routerLink="/home">
          <IonIcon slot="start" icon={homeIcon}></IonIcon>
          <IonLabel>
            {/* <Link to="/home">Startseite</Link> */}
            Kostenstelle wählen
          </IonLabel>
        </IonItem>
        {appLoggedIn && (
          <>
            <IonItem button routerLink="/zusatzstoffe">
              <IonIcon slot="start" icon={readerIcon}></IonIcon>
              <IonLabel>
                {/* <Link to="/order">Menü-Bestellungen</Link> */}
                Zusatzstoffe
              </IonLabel>
            </IonItem>
          </>
        )}

        <IonItem
          onClick={() => {
            logout();
          }}
          button
          routerLink="/welcome"
        >
          <IonIcon slot="start" color="danger" icon={returnIcon}></IonIcon>
          <IonLabel>Abmelden</IonLabel>
        </IonItem>
        {/* <IonItem button>
            <IonIcon slot="start" icon={trailIcon}></IonIcon>
            <IonLabel>
            Wegweiser
            </IonLabel>
          </IonItem> 

        
          <IonItem button routerLink="/info">
            <IonIcon slot="start" icon={infoIcon}></IonIcon>
            <IonLabel>
            Informationen zum Haus

            </IonLabel>
          </IonItem>
*/}
        <IonToast
          isOpen={toast}
          color="dark"
          onDidDismiss={() => setToast(false)}
          message={`Für diese App ist ein Update verfügbar ( ${version} -> ${difference} )`}
          duration={2000}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>
            <h6>version {version}</h6>
          </IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
}
