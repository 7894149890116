import {useEffect, useState} from 'react';
import { Storage } from '@capacitor/storage';


import {
  IonCard,
  IonContent,
  IonGrid,
  IonCardContent,
  IonRow,
  IonCol,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCardTitle,
  IonLabel,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./Qr.css";
import 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";
export default function Nahrung(props) {


  const [cartData, setCartData] = useState(null)
  const [kcal, setKcal] = useState(0)
  const [kh, setKh] = useState(0)
  const [proteine, setProteine] = useState(0)
  const [fett, setFett] = useState(0)
  const [salz, setSalz] = useState(0)
  const [gesFett, setGesFett] = useState(0)
  const [zucker, setZucker] = useState(0)




  const checkStorage = async () => {

    const {value} = await Storage.get({ key: 'cart' })
    // // console.log("value", value)
    if (value !== null) {setCartData(JSON.parse(value))}
    
    }

  useEffect(()=>{

    checkStorage()

  },[])

  const getKcal = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push( (((komp.kcal)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

setKcal(sumWithInitial)


      
    }

  }

  const getKh = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.kh) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

 setKh(sumWithInitial)

      
    }

  }

  const getProteine = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.eiweiss) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

setProteine(sumWithInitial)


      
    }

  }

  
  const getGesFett = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.gesfett) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

  setGesFett(sumWithInitial)


}
    }
  
    
    const getFett = () => {

      if (cartData !== null) {
  
        let tempCart = cartData;
  
        let tempSumme = [];
        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.fett) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
  const sumWithInitial = tempSumme.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue)
  
    setFett(sumWithInitial)
  
  
        
      }
  
    }

    const getZucker = () => {

      if (cartData !== null) {
  
        let tempCart = cartData;
  
        let tempSumme = [];
        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.zucker) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
  const sumWithInitial = tempSumme.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue)
  
    setZucker(sumWithInitial)
  
  
        
      }
  
    }

    const getSalz = () => {

      if (cartData !== null) {
  
        let tempCart = cartData;
  
        let tempSumme = [];
        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.salz) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
  const sumWithInitial = tempSumme.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue)
  
    setSalz(sumWithInitial)
  
  
        
      }
  
    }

  useEffect(()=>{

    getKcal();
    getKh();
    getProteine();
    getFett();
    getGesFett();
    getZucker();
    getSalz();

  },[cartData])

  const doughnutChartData = {
    labels: ["KCal", "verfügbarer Tagesbedarf"],
    datasets: [
      {
        label: "Kalorien",
        backgroundColor: ["lightgreen", '#C0C0C0'],
        borderWidth: 1,
        data: [kcal && kcal, kcal && ( 2550 - kcal)],
      },
    ],
  };
  const doughnutChartDataKH = {
    labels: ["Kohlenhydrate", "verfügbarer Tagesbedarf"],
    datasets: [
      {
        label: "Kalorien",
        backgroundColor: ["lightblue", '#C0C0C0'],
        borderWidth: 1,
        data: [kh && kh, kh && ( 293.3 - kh)],
      },
    ],
  };
  const doughnutChartDataFett = {
    labels: ["Überschreitung", "Fett"],
    datasets: [
      {
        label: "Fett",
        backgroundColor: ["red", 'orange'],
        borderWidth: 1,
        data: [fett && fett, fett && ( 92.5 - fett)],
      },
    ],
  };
  const doughnutChartDataEi = {
    labels: ["Proteine", "verfügbarer Tagesbedarf"],
    datasets: [
      {
        label: "Proteine",
        backgroundColor: ["yellow", '#C0C0C0'],
        borderWidth: 1,
        data: [proteine && proteine, proteine && ( 55.5 - proteine)],
      },
    ],
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Nährwerte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Nährwerte</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard> <IonCardHeader>
          <IonCardTitle>
            <IonLabel>Übersicht Dienstag 29.03.2021</IonLabel>
          </IonCardTitle>
          <IonCardSubtitle>Tagesbedarfe</IonCardSubtitle>
         </IonCardHeader>
          <IonCardContent>
          <IonGrid>
            <IonRow>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={doughnutChartData} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={doughnutChartDataKH} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={doughnutChartDataEi} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={doughnutChartDataFett} /></IonCol>
            </IonRow>
          </IonGrid>
            <IonCardSubtitle>Werte</IonCardSubtitle>
            <IonGrid>
              <IonRow>
              <IonCol>KCal</IonCol>
              <IonCol>{Math.round(kcal)}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>KJ</IonCol>
                <IonCol>{Math.round(kcal*4.1868)}</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>Fett</IonCol>
              <IonCol>{Math.round(fett)}g</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>davon ges. Fettsäuren</IonCol>
              <IonCol>{Math.round(gesFett)}g</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>Kohlenhydrate</IonCol>
              <IonCol>{Math.round(kh)}g</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>davon Zucker</IonCol>
              <IonCol>{Math.round(zucker)}g</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>Eiweiß</IonCol>
              <IonCol>{Math.round(proteine)}g</IonCol>
              </IonRow>
              <IonRow>
              <IonCol>Salz</IonCol>
              <IonCol>{Math.round(salz)}g</IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
