import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  setupIonicReact,
} from "@ionic/react";
import { IonContent } from "@ionic/react";

import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import informationIcon from "./assets/information-circle.svg";
import nutritionIcon from "./assets/nutrition.svg";
import readerIcon from "./assets/reader.svg";
import returnIcon from "./assets/return.svg";

import { Storage } from "@capacitor/storage";

import { useContext } from "react";
import { DataContext } from "./context/DataContext";

setupIonicReact();

export default function SidebarRight() {
  // console.log("<SidebarRight/>");
  const {
    appServer,
    appServerChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
  } = useContext(DataContext);

  const logout = async () => {
    await Storage.remove({ key: "abend" });
    await Storage.remove({ key: "auswahl" });
    await Storage.remove({ key: "bewohner" });
    await Storage.remove({ key: "frueh" });
    await Storage.remove({ key: "mittag" });
    window.localStorage.removeItem( "bewohner" );
    window.localStorage.removeItem( "haeuser" );
    window.localStorage.removeItem( "rezepte" );
    window.localStorage.removeItem( "server" );
    window.localStorage.removeItem( "speiseplan" );
    window.localStorage.removeItem( "stationen" );
    window.localStorage.removeItem( "token" );
    appLoggedInChange(false)
  };

  //  // console.log(props)

  return (
    <IonMenu side="end" contentId="right" type="overlay">
      <IonContent>
        <IonButton
          expand="full"
          color="secondary"
          size="large"
          disabled
        ></IonButton>

        {/* </IonItem> */}

        {appLoggedIn && (
          <>
            <IonItem button routerLink="/zusatzstoffe">
              <IonIcon slot="start" icon={readerIcon}></IonIcon>
              <IonLabel>
                {/* <Link to="/order">Menü-Bestellungen</Link> */}
                Zusatzstoffe
              </IonLabel>
            </IonItem>
          </>
        )}

        <IonItem
          onClick={() => {
            logout();
          }}
          button
          routerLink="/welcome"
        >
          <IonIcon slot="start" color="danger" icon={returnIcon}></IonIcon>
          <IonLabel>Abmelden</IonLabel>
        </IonItem>
      </IonContent>
    </IonMenu>
  );
}
