import {
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonButton,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react"
import "./Qr.css";
import { useParams } from "react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useEffect, useState } from "react";
import { Storage } from "@capacitor/storage";
import { useHistory } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { checkmarkCircle } from "ionicons/icons";
import { useIonRouter } from "@ionic/react";

import { useContext } from 'react';
import {DataContext} from '../context/DataContext'


export default function Woche() {

  const router = useIonRouter();

  const [bewohner, setBewohner] = useState([
    {
      betriebe_wb_ausblenden: "true",
      betriebe_wb_betriebe_id: "12",
      betriebe_wb_id: "30",
      betriebe_wb_name: "Lade Stationen",
    },
  ]);
  
  const { appSollReloadAuswahlChange,appSollReloadAuswahl,appBewohnerZusatzstoffeChange , appServer, appStation,appStationBezeichnung,appStationBezeichnungChange,appStationChange,appBewohnerSollId,appBewohnerSollBezeichnung} = useContext(DataContext);


  const [segment, setSegment] = useState("2");
  const [auswahl, setAuswahl] = useState();
  const [today, setToday] = useState(null);

  
  
  function getData () {

    
    // console.log("hole daten");
    var myHeaders = new Headers();
    myHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
    };
    fetch(appServer + "/funktionen/api2/senioren/auswahl.php?bewhohner_id="+appBewohnerSollId, requestOptions)
    .then((response) => response.json())
    .then(async (result) => {
      setAuswahl(JSON.stringify(result))
      // console.log("result", result);
      let storagedata = {
        key: "auswahl",
        value: JSON.stringify(result),
      };
      
      // console.log("auswahl", storagedata);
      await Storage.set(storagedata);
    });




  }

  const [woche1, setWoche1] = useState(woche(1));
  const [woche2, setWoche2] = useState(woche(2));
  const [woche3, setWoche3] = useState(woche(3));
  const [woche4, setWoche4] = useState(woche(4));
  const [woche5, setWoche5] = useState(woche(5));
  const [woche6, setWoche6] = useState(woche(6));
  const [woche7, setWoche7] = useState(woche(7));

 


  function woche(tag) {
    let color1
    let color2
    let color3
    
    
    
    return (
      
      <>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              {today && formatDate(today.toString(), tag)}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div className={`flexbox-panel multiple`}>
              <div
                className="flexbox-item"
                
                onClick={() =>
                  router.push(
                    "/fruehs/" +
                    appStation +
                    "/" +
                    appStationBezeichnung +
                    "/" +
                    appBewohnerSollId +
                      "/" +
                      tag +
                      "/" +
                      segment +
                      "/" +
                      appBewohnerSollBezeichnung+
                      "/" +
                      formatDateSQL(today.toString(), tag)
                  )
                }
                style={{
                  background: `url('/assets/frueh.jpeg') no-repeat center center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="overlay-container">
                  <div className="overlay-item-bottom bg1">Frühstück</div>
                </div>
              </div>

              <div
                className="flexbox-item"
                onClick={() =>
                  router.push(
                    "/mittag/" +
                    appStation +
                    "/" +
                    appStationBezeichnung +
                    "/" +
                      tag +
                      "/" +
                      segment +
                      "/" +
                      appBewohnerSollId +
                      "/" +
                      appBewohnerSollBezeichnung+
                      "/" +
                      formatDateSQL(today.toString(), tag)
                  )
                }
                style={{
                  background: `url('/assets/mittag.jpeg') no-repeat center center`,
                  backgroundSize: "cover",
                }}
              >
                <div className="overlay-container">
                  <div className="overlay-item-bottom bg1">Mittag</div>
                </div>
              </div>
              <div
                className="flexbox-item"
                onClick={() =>
                  router.push(
                    "/abend/" +
                    appStation +
                    "/" +
                    appStationBezeichnung +
                    "/" +
                    appBewohnerSollId +
                      "/" +
                      tag +
                      "/" +
                      segment +
                      "/" +
                      appBewohnerSollBezeichnung+
                      "/" +
                      formatDateSQL(today.toString(), tag)
                  )
                }
                style={{
                  background: `url('/assets/abend.jpeg') no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                <div className="overlay-container">
                  <div className="overlay-item-bottom bg1">Abendessen</div>
                </div>
              </div>
            </div>

            <div className={`flexbox-panel`}>
              <div className="flexbox-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {auswahl && JSON.parse(auswahl).map((elementInArray) => {
                   
                   
                   if(elementInArray['bewohner_verzehr_zeit']==="1" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){
                    color1=<CheckCircleIcon color="success" />
                  }
                  
                  })}

                  {color1}
                    Bestellung 
                  </AccordionSummary>
                  
                  {auswahl && JSON.parse(auswahl).map((elementInArray) => {
                  if(elementInArray['bewohner_verzehr_zeit']==="1" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){

                  return (
                    
                      
                    <AccordionDetails> 
                                            {elementInArray['bewohner_verzehr_menge']} x {elementInArray['rezepte_bezeichnung']}

                      </AccordionDetails>
                    )}
    
    
})}
                
                </Accordion>
              </div>
              <div className="flexbox-item">
              <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                  >
                    {auswahl && JSON.parse(auswahl).map((elementInArray) => {
                   
                   
                   if(elementInArray['bewohner_verzehr_zeit']==="2" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){
                    color2=<CheckCircleIcon color="success" />
                  }
                  
                  })}

                  {color2}
                    Bestellung 
                  </AccordionSummary>
                  
                  {auswahl && JSON.parse(auswahl).map((elementInArray) => {
                   
                    if(elementInArray['bewohner_verzehr_zeit']==="2" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag))
                    {
                      // console.log("treffer", elementInArray['rezepte_bezeichnung'])
                      // console.log("Bestellung tag",elementInArray['bewohner_verzehr_tag'])
                      // console.log("Bestellung sqltag",formatDateSQL(today.toString(), tag))
                    }
                    if(elementInArray['bewohner_verzehr_zeit']==="2" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){
                  return (
                    
                      
                    <AccordionDetails> 
                      {elementInArray['bewohner_verzehr_menge']} x {elementInArray['rezepte_bezeichnung']}
                      </AccordionDetails>
                    )}
    
    
})}
                
                </Accordion>
              </div>
              <div className="flexbox-item">
              <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    color={color3}
                  >{auswahl && JSON.parse(auswahl).map((elementInArray) => {
                   
                   
                    if(elementInArray['bewohner_verzehr_zeit']==="3" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){
                     color3=<CheckCircleIcon color="success" />
                    }
                   
                   })}
 
                   {color3}
                    Bestellung
                  </AccordionSummary>
                  
                  {auswahl && JSON.parse(auswahl).map((elementInArray) => {
                  if(elementInArray['bewohner_verzehr_zeit']==="3" && elementInArray['bewohner_verzehr_tag'] ===formatDateSQL(today.toString(), tag)){
                  return (
                    
                      
                    <AccordionDetails>
                                            {elementInArray['bewohner_verzehr_menge']} x {elementInArray['rezepte_bezeichnung']}

                      </AccordionDetails>
                    )}
    
    
})}
                
                </Accordion>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </>
    );
  }

  function formatDateSQL(string, tag) {
    let year = string.substr(0, 4);
    let month = string.substr(4, 2) - 1;
    let day = string.substr(6, 2);
    
    const date = new Date(year, month, day);
    let wochentag = date.getDay()-1;
    //// console.log("wochentag", wochentag);
    date.setDate(date.getDate() + (((segment * 7) - wochentag))+(tag-1));
    date.getDate()
    //// console.log("date", date.toLocaleDateString("de-DE"));

    let return_day=date.toLocaleDateString("en-GB", {
      
      day: "2-digit"
     
    });

    let return_month=date.toLocaleDateString("en-GB", {
      
     
      month: "2-digit"
    });

    let return_year=date.toLocaleDateString("en-GB", {
      
     
      
      year: "numeric"
    });
    //// console.log(return_day+'-'+return_month+'-'+return_year)
    return return_year+'-'+return_month+'-'+return_day;
  }

  function formatDate(string, tag) {
    let year = string.substr(0, 4);
    let month = string.substr(4, 2) - 1;
    let day = string.substr(6, 2);
    
    const date = new Date(year, month, day);
    let wochentag = date.getDay()-1;
    //// console.log("wochentag", wochentag);
    date.setDate(date.getDate() + (((segment * 7) - wochentag))+(tag-1));
    date.getDate()
    //// console.log("date", date.toLocaleDateString("de-DE"));

    return date.toLocaleDateString("de-DE", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "2-digit",
    });
  }

  function formatWoche(woche) {
    
    
    const date = new Date();
    const date_ende = new Date();
    let wochentag = date.getDay()-1;
    //// console.log("wochentag", wochentag);
    date.setDate(date.getDate() + (((woche * 7) - wochentag)));
    date_ende.setDate(date.getDate() +6);
    //// console.log("date", date.toLocaleDateString("de-DE"));

    return date.toLocaleDateString("de-DE", {
      
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })+" - "+date_ende.toLocaleDateString("de-DE", {
      
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  }

  function getNow() {
    let event = new Date().toISOString().split("T")[0].split("-");

    let datenew = event[0] + event[1] + event[2];
    // // console.log(datenew)
    setToday(datenew);
  }


  useEffect(
    () =>
      {
        //getBewohner()
      } ,
    []
  ) ;



  useEffect(() => {
    getNow();
    setWoche1(woche(1))
    setWoche2(woche(2))
    setWoche3(woche(3))
    setWoche4(woche(4))
    setWoche5(woche(5))
    setWoche6(woche(6))
    setWoche7(woche(7))
  }, [segment,auswahl]);
  

  useEffect(() => {
    const storageBewohner = async () => {
      await window.localStorage.setItem("bewohner", JSON.stringify(bewohner));

      // console.log("storageBewohner");
    };
    storageBewohner();

    if( Array.isArray( bewohner.bewohner_zusatzstoffe ) ) {
      appBewohnerZusatzstoffeChange( bewohner.bewohner_zusatzstoffe )
    }

  }, [bewohner]);
  
  
  
  useEffect(() => {
    if(appServer !== undefined && appSollReloadAuswahl==true){
      getData();
      appSollReloadAuswahlChange(false);
    }  

  }, [appBewohnerSollId,appSollReloadAuswahl]);

/*     useIonViewDidEnter(() => {
    if(appServer !== undefined){
      getData()
    }   
    
  
  }); 
  */
 
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{appBewohnerSollBezeichnung} ({appStationBezeichnung})

          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">{appBewohnerSollBezeichnung} ({appStationBezeichnung})

            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton 
            routerLink={'/bewohner/'+appStation+'/'+appStationBezeichnung}
          
        >
          zurück
        </IonButton>

        <IonSegment value={segment} onClick={(e) => setSegment(e.target.value)}>
          <IonSegmentButton value="0">
          <IonLabel>{formatWoche(0)}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="1">
            
          <IonLabel>{formatWoche(1)}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="2">
            <IonLabel>{formatWoche(2)}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="3">
            <IonLabel>{formatWoche(3)}</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        {woche1}
        {woche2}
        {woche3}
        {woche4}
        {woche5}
        {woche6}
        {woche7}
      </IonContent>
    </IonPage>
  );
}
