import { IonContent, IonButton,IonList, IonIcon, IonLabel, IonItem, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import readerIcon from '../assets/reader.svg'
import { useContext } from 'react';
import {DataContext} from '../context/DataContext'


export default function Stationen() {
  const { appErfassung , appStationen , appStationChange, appStationenMenuesChange, appStationBezeichnungChange } = useContext(DataContext);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Wohnbereiche</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Wohnbereiche</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton routerLink={'/funktionen/x/x/'}>zurück</IonButton>

        <IonList>
          {
            appStationen && [ appStationen.map( ( elementInArray ) => (
              elementInArray.betriebe_wb_ausblenden === "false"
                ?
                  <IonItem
                    key = { 'betriebe_wb_' + elementInArray.betriebe_wb_id }
                    button
                    routerLink={ '/' + appErfassung + '/' + elementInArray.betriebe_wb_id + '/' + elementInArray.betriebe_wb_name }
                    onClick =
                      {
                        () =>
                          {

                            appStationenMenuesChange(elementInArray.betriebe_anzahl_menues);
                            // console.log('elementInArray.betriebe_anzahl_menues',elementInArray.betriebe_anzahl_menues)
                            appStationChange( elementInArray.betriebe_wb_id ) ;
                            appStationBezeichnungChange(elementInArray.betriebe_wb_name);
                          }
                      }
                  >
                    <IonIcon slot="start" icon={readerIcon}></IonIcon>
                    <IonLabel>
                      {elementInArray.betriebe_wb_name}
                    </IonLabel>
                  </IonItem>
                :
                  ''
            ) ) ]
          }          
        </IonList>
      </IonContent>
    </IonPage>
  )

}