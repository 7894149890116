import { IonContent, IonList, IonIcon, IonLabel, IonItem, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import readerIcon from '../assets/reader.svg'
import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function Home() {
  const { appHaeuser , appHausChange } = useContext(DataContext);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Kostenstellen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Kostenstellen</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {
            appHaeuser && appHaeuser.map( e => 
              <IonItem
                key = { 'betrieb_' + e.betriebe_id }
                button
                routerLink = { `/funktionen/x/x` }
                onClick =
                  {
                    () =>
                      {
                        appHausChange( e.betriebe_id ) ;
                      }
                  }
              >
                <IonIcon slot="start" icon={readerIcon}></IonIcon>
                <IonLabel>
                  {e.betriebe_name}
                </IonLabel>
              </IonItem>
            )
          }

        </IonList>
      </IonContent>
    </IonPage>
  )
}