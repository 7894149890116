import { IonCard, IonList, IonGrid,IonCol,IonRow,IonCardContent, IonItem, IonSegment, IonSegmentButton, IonInput, IonButton, IonNote, IonLabel, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
//import ccm_map from '../assets/ccm_map.png';

export default function Welcome(props) {


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Zugriff verweigert</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Zugriff verweigert</IonTitle>
          </IonToolbar>
        </IonHeader>
       

        <IonCard>     
    <IonCardContent color="danger">
            
            
            <b>Zutritt verweigert.</b> Sie müssen eingeloggt sein, um diese Seite sehen zu können.
            <IonList>
            <IonItem button routerLink={"/welcome"}>
Einloggen </IonItem>  </IonList>
            </IonCardContent>

        </IonCard>        
       
      </IonContent>
    </IonPage>
  );
};