import { IonApp, IonToolbar, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRouterOutlet, IonSplitPane, IonTitle, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import SidebarRight from './SidebarRight';
import SidebarLeft from './SidebarLeft';
import Home from './pages/Home';
import Zusatzstoffe from './pages/Zusatzstoffe';
import Woche from './pages/Woche';
import Fruehstueck from './pages/Fruehs';
import Nahrung from './pages/Nahrung';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useState } from 'react';
import Welcome from "./pages/Welcome"
import Forbidden from "./pages/Forbidden"
import Mittag from "./pages/Mittag"
import Abend from "./pages/Abend"
import Stationen from "./pages/Stationen"
import Funktionen from "./pages/Funktionen"
import Bewohner from './pages/Bewohner';
import BewohnerIst from './pages/BewohnerIst';
import TagIst from './pages/TagIst';



import {DataContext} from './context/DataContext'
import AppContext from './context/AppContext';
import Stationsbedarf from './pages/Stationsbedarf';

import { dbQuery, dbBufferWorker , exportDbBufferIn , exportDbBufferOut } from './components/DBSqlite';


setupIonicReact();

const App = () => {

  const [ appBewohnerZusatzstoffe , setAppBewohnerZusatzstoffe ] = useState( [] ) ;
  const [ appErfassung , setAppErfassung ] = useState( '' ) ;
  const [ appHaeuser , setAppHaeuser ] = useState( [] ) ;
  const [ appHaus , setAppHaus ] = useState( '' ) ;
  const [ appLoggedIn , setAppLoggedIn ] = useState( false ) ;
  const [ appServer , setAppServer ] = useState( '' ) ;
  const [ appStationen , setAppStationen ] = useState( [] ) ;
  const [ appStationenMenues , setAppStationenMenues ] = useState( [] ) ;
  const [ appStation , setAppStation ] = useState( '' ) ;
  const [ appToken , setToken ] = useState( false ) ;
  const [ appZusatzstoffe , setAppZusatzstoffe ] = useState( [] ) ;
  const [ appBewohnerId , setAppBewohnerId ] = useState( [] ) ;
  const [ appBewohnerSollId , setAppBewohnerSollId ] = useState( [] ) ;
  const [ appBewohnerSollBezeichnung , setAppBewohnerSollBezeichnung ] = useState( [] ) ;
  const [ appBewohnerIndex , setAppBewohnerIndex ] = useState( [] ) ;
  const [ appStationBezeichnung , setAppStationBezeichnung ] = useState(  ) ;
  const [ appStationsbedarf , setAppStationsbedarf ] = useState( [] ) ;
  const [ appStationsbedarf1 , setAppStationsbedarf1 ] = useState( true ) ;
  const [ appStationsbedarf2 , setAppStationsbedarf2 ] = useState( true );
  const [ appStationsbedarf3 , setAppStationsbedarf3 ] = useState( true ) ;
  const [ appStationsbedarf4 , setAppStationsbedarf4 ] = useState( true ) ;
  const [ appStationsbedarf5 , setAppStationsbedarf5 ] = useState( true ) ;
  const [ appStationsbedarf6 , setAppStationsbedarf6 ] = useState( true ) ;
  const [ appStationsbedarf7 , setAppStationsbedarf7 ] = useState( true ) ;
  const [ appStationsbedarfMinBestellDate , setAppStationsbedarfMinBestellDate ] = useState( [] ) ;
  const [ appStationsbedarfFilter , setAppStationsbedarfFilter ] = useState( [] ) ;
  const [ appStationsbedarfVorlauf , setAppStationsbedarfVorlauf ] = useState( 36 ) ;
  const [ appSollReloadAuswahl , setAppSollReloadAuswahl ] = useState(  ) ;


  

  let dataContextValue = { 
    appBewohnerZusatzstoffe: appBewohnerZusatzstoffe ,
    appBewohnerZusatzstoffeChange: setAppBewohnerZusatzstoffe ,
    appErfassung: appErfassung,
    appErfassungChange: setAppErfassung,
    appBewohnerId: appBewohnerId,
    appBewohnerIdChange: setAppBewohnerId,

    appBewohnerSollId: appBewohnerSollId,
    appBewohnerSollIdChange: setAppBewohnerSollId,
    appBewohnerSollBezeichnung: appBewohnerSollBezeichnung,
    appBewohnerSollBezeichnungChange: setAppBewohnerSollBezeichnung,
    appBewohnerIndex: appBewohnerIndex,
    appBewohnerIndexChange: setAppBewohnerIndex,
    appHaeuser: appHaeuser,
    appHaeuserChange: setAppHaeuser,
    appHaus: appHaus,
    appHausChange: setAppHaus,
    appLoggedIn: appLoggedIn,
    appLoggedInChange: setAppLoggedIn,
    appServer: appServer,
    appServerChange:setAppServer,
    appStationen: appStationen,
    appStationenChange: setAppStationen,
    appStation: appStation,
    appStationChange: setAppStation,
    appStationBezeichnung: appStationBezeichnung,
    appStationBezeichnungChange: setAppStationBezeichnung,
    appToken: appToken,
    appTokenChange: setToken,
    appZusatzstoffe: appZusatzstoffe ,
    appZusatzstoffeChange: setAppZusatzstoffe ,
    appStationenMenues: appStationenMenues ,
    appStationenMenuesChange: setAppStationenMenues ,
    appStationsbedarf:appStationsbedarf,
    appStationsbedarfChange:setAppStationsbedarf,
    appStationsbedarf1:appStationsbedarf1,
    appStationsbedarf1Change:setAppStationsbedarf1,
    appStationsbedarf2:appStationsbedarf2,
    appStationsbedarf2Change:setAppStationsbedarf2,
    appStationsbedarf3:appStationsbedarf3,
    appStationsbedarf3Change:setAppStationsbedarf3,
    appStationsbedarf4:appStationsbedarf4,
    appStationsbedarf4Change:setAppStationsbedarf4,
    appStationsbedarf5:appStationsbedarf5,
    appStationsbedarf5Change:setAppStationsbedarf5,
    appStationsbedarf6:appStationsbedarf6,
    appStationsbedarf6Change:setAppStationsbedarf6,
    appStationsbedarf7:appStationsbedarf7,
    appStationsbedarf7Change:setAppStationsbedarf7,
    appStationsbedarfFilter:appStationsbedarfFilter,
    appStationsbedarfFilterChange:setAppStationsbedarfFilter,
    appStationsbedarfVorlauf:appStationsbedarfVorlauf,
    appStationsbedarfVorlaufChange:setAppStationsbedarfVorlauf,
    appStationsbedarfMinBestellDate:appStationsbedarfMinBestellDate,
    appStationsbedarfMinBestellDateChange:setAppStationsbedarfMinBestellDate,
    appSollReloadAuswahl:appSollReloadAuswahl,
    appSollReloadAuswahlChange:setAppSollReloadAuswahl
  };

  return (
    <DataContext.Provider value={dataContextValue}>
    <AppContext />
    <IonApp>
      <IonPage>
        <IonHeader >
          <IonToolbar>

            <IonButtons slot="start">
              <IonMenuButton menu="start" auto-hide="false" color="primary"></IonMenuButton>
            </IonButtons>

            {/* <IonButtons slot="end">
              <IonMenuButton menu="end" auto-hide="false">
                <IonButton>

                  
                    <IonIcon slot="icon-only" src={personCircle} size="large" color="primary"></IonIcon>
                  </StyledBadge> : <IonIcon slot="icon-only" src={personCircle} size="large" color="primary"></IonIcon>
                  

                </IonButton>
              </IonMenuButton>
            </IonButtons> */}

            <IonTitle>Senioren Menüerfassung</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader>

          </IonHeader>


          <IonSplitPane when="(min-width: 2024px)" contentId="main">
            {/*--  the side menu  --*/}
            { (appLoggedIn === false) ? (<Forbidden/>) : <SidebarLeft />}
            

            {/*-- the main content --*/}
            <IonPage id="main">

              <IonReactRouter>
                <IonRouterOutlet>
                  <Route exact path="/welcome">
                    <Welcome/>
                  </Route>
                  <Route exact path="/home">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Home />}
                  </Route>
                  <Route exact path="/stationen/:hausid/:hausbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Stationen />}
                  </Route>
                  <Route exact path="/bewohner/:stationsid/:stationsbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Bewohner />}
                  </Route>
                  <Route exact path="/bewohnerist/:stationsid/:stationsbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <BewohnerIst />}
                  </Route>
                  <Route exact path="/tag/:mahlzeit/:datum/:bewohnerid/:bewohnerbezeichnung">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <TagIst />}
                  </Route>
                  <Route exact path="/zusatzstoffe">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Zusatzstoffe/>}
                  </Route>
                  <Route exact path="/funktionen/:stationsid/:stationsbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Funktionen />}
                  </Route>
                  <Route exact path="/stationsbedarf/:stationsid/:stationsbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Stationsbedarf />}
                  </Route>
                  <Route exact path="/woche/:stationsid/:stationsbezeichnung/:bewohnerid/:bewohnerbezeichnung">
                  { (appLoggedIn === false)  ? (<Forbidden/>) : <Woche />}
                  </Route>
                  <Route exact path="/nahrung">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Nahrung />}
                  </Route>
                  <Route exact path="/abend/:stationsid/:stationsbezeichnung/:bewohnerid/:tag/:woche/:bewohnerbezeichnung/:datumsql">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Abend />}
                  </Route>
                  <Route exact path="/mittag/:stationsid/:stationsbezeichnung/:tag/:woche/:bewohnerid/:bewohnerbezeichnung/:datumsql">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Mittag />}
                  </Route>
                  <Route exact path="/fruehs/:stationsid/:stationsbezeichnung/:bewohnerid/:tag/:woche/:bewohnerbezeichnung/:datumsql">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Fruehstueck />}
                  </Route>
                  
                  <Route exact path="/">
                       {appLoggedIn ? <Redirect to="/home" /> : <Redirect to="/welcome" />}
                  </Route>
                  <Route exact path="/zusatzstoffe">
                    { (appLoggedIn === false)  ? (<Forbidden/>) : <Zusatzstoffe/>}
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            </IonPage>

            { appLoggedIn && (<SidebarRight/>)}


          </IonSplitPane>


        </IonContent>
      </IonPage>
    </IonApp>
    </DataContext.Provider>

  );
};

export default App;
