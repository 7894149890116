import { useContext } from "react";
import { DataContext } from "./DataContext";
import { useEffect } from "react";
import { Storage } from "@capacitor/storage";
import { useIonViewWillEnter } from "@ionic/react";
import axios from 'axios';
import { dbQuery, dbBufferWorker , exportDbBufferIn , exportDbBufferOut } from '../components/DBSqlite';


const AppData = () => {
  // console.log(" Lade AppData");

  const {
    appServer,
    appServerChange,
    appHaeuser,
    appHaeuserChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
    appHaus,
    appStation,
    appStationen,
    appStationenChange ,
    appStationenMenues,
    appStationenMenuesChange,
    appZusatzstoffeChange,
    appStationsbedarfChange,
    appStationsbedarf,
    appStationsbedarfFilter,
    appStationsbedarfFilterChange,
    appStationsbedarfVorlauf,
    appStationsbedarf1Change,
    appStationsbedarf2Change,
    appStationsbedarf3Change,
    appStationsbedarf4Change,
    appStationsbedarf5Change,
    appStationsbedarf6Change,
    appStationsbedarf7Change,
    appStationsbedarfVorlaufChange,
    appStationsbedarfMinBestellDateChange
  } = useContext(DataContext);

  const checkToken = async () => {
    const { value } = await Storage.get({ key: "token" });
    if (!value) {
      // console.log("checktoken", value);
      return null;
    } else return value;
  };

  const checkServer = async () => {
    const { value } = await Storage.get({ key: "server" });
    if (!value) {
      // console.log("server", value);
      return null;
    } else return value;
  };


  const getDataZusatzstoffe = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
    };
    fetch(appServer + "/funktionen/api2/senioren/zusatzstoffe.php", requestOptions)
    .then((response) => response.json())
    .then(async (result) => {
      appZusatzstoffeChange( result ) ;
    });
  }

  useEffect(
    () =>
      {
        if( appLoggedIn === true && appServer ) {
          getDataZusatzstoffe() ;
        }
      } ,
      [ appServer ]
  ) ;
  useEffect(
    () =>
      {
        let now = Date.now()
        console.log('now',now)
        let vorlauf_sekunden = appStationsbedarfVorlauf* 60 * 60 * 1000
        console.log('vorlauf_sekunden', vorlauf_sekunden)

        console.log('minbestelldate', now+vorlauf_sekunden)

        function padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }
        function formatDate(date) {
          return (
            [
              date.getFullYear(),
              padTo2Digits(date.getMonth() + 1),
              padTo2Digits(date.getDate()),
            ].join('-') 
           
          );
        }
        console.log('Date minbestelldate', formatDate(new Date(now+vorlauf_sekunden)))

        appStationsbedarfMinBestellDateChange(formatDate(new Date(now+vorlauf_sekunden)))

      } ,
      [ appStationsbedarfVorlauf ]
  ) ;
  
  const getHaeuser = () => {
   
    // console.log('appServer',appServer)
    //validate inputs code not shown
    const fetchData = {
      token: appToken,
      //BetriebeId: BetriebeId,
    };
    const api = axios.create({
      baseURL: appServer+'/funktionen/api2/senioren/haeuser.php',
    });
    api.get("", fetchData)
      .then((res) => {
        
          let data = res.data;
          appHaeuserChange(data)
          // console.log('häuser',data)
          
          

})
  }

  const getStationsbedarf = () => {
   
    // console.log('appServer',appServer)
    //validate inputs code not shown
    const fetchData = {
      token: appToken,
      //BetriebeId: BetriebeId,
    };
    const api = axios.create({
      baseURL: appServer+'/funktionen/api2/senioren/stationsbedarf.php',
    });
    api.get("", fetchData)
      .then((res) => {
        
          let data = res.data;
          appStationsbedarfChange(data)
          // console.log('stationsbedarf',data)
          
  })
  }

  useEffect( () => {
    if(appLoggedIn===true && appServer){
    getHaeuser();
    getStationsbedarf();
    }
},[appLoggedIn,appServer])

useEffect( () => {
  if(appLoggedIn===true && appServer && appStation){
    console.log('useEffect appStation fired')
    
    let StationbedarfListe = Array();
    let vorlauf = 24;
    let tag1 = false;
    let tag2 = false;
    let tag3 = false;
    let tag4 = false;
    let tag5 = false;
    let tag6 = false;
    let tag7 = false;
    
      appStationsbedarf.forEach((elementInArray) => {
        if(elementInArray.aufgaben_zuordnung_bezug === appStation){
          StationbedarfListe.push(elementInArray)
          vorlauf=elementInArray.aufgaben_zuordnung_vorlauf
          if(elementInArray.aufgaben_zuordnung_1==="1"){
            tag1=true
          }
          if(elementInArray.aufgaben_zuordnung_2==="1"){
            tag2=true
          }
          if(elementInArray.aufgaben_zuordnung_3==="1"){
            tag3=true
          }
          if(elementInArray.aufgaben_zuordnung_4==="1"){
            tag4=true
          }
          if(elementInArray.aufgaben_zuordnung_5==="1"){
            tag5=true
          }
          if(elementInArray.aufgaben_zuordnung_6==="1"){
            tag6=true
          }
          if(elementInArray.aufgaben_zuordnung_7==="1"){
            tag7=true
          }

        }

        
      })
        
      console.log('StationbedarfListe',StationbedarfListe)
      appStationsbedarfFilterChange(StationbedarfListe)
      console.log('vorlauf',vorlauf)
      appStationsbedarfVorlaufChange(vorlauf)
      appStationsbedarf1Change(tag1)
      appStationsbedarf2Change(tag2)
      appStationsbedarf3Change(tag3)
      appStationsbedarf4Change(tag4)
      appStationsbedarf5Change(tag5)
      appStationsbedarf6Change(tag6)
      appStationsbedarf7Change(tag7)
  }
},[appStation])

const getStationen = () => {
  // console.log('appServer',appServer)
  //validate inputs code not shown
  const fetchData = {
    token: appToken,
    //BetriebeId: BetriebeId,
  };
  const api = axios.create({
    baseURL: appServer+'/funktionen/api2/senioren/wohnbereiche.php?haus='+appHaus,
  });
  api.get("", fetchData)
    .then((res) => {
      
        let data = res.data;
        appStationenChange(data)
        // console.log('Stationen',appStationen)
        
        

})
}

useEffect( () => {
  if(appLoggedIn===true && appHaus &&appServer){

  getStationen();
  }
},[appHaus,appLoggedIn,appServer])



  useEffect(() => {
    const storageHaeuser = async () => {
  
      await window.localStorage.setItem("haeuser",JSON.stringify(appHaeuser))
      
      // console.log("store haeuser");
    };

    storageHaeuser();
  
  }, [appHaeuser]);




  useIonViewWillEnter(() => {
    // console.log("ionViewWillEnter event fired");

    checkToken().then((result) => {
      // // console.log("checktokenresult", result)
      if (result !== null) {
        // // console.log("token result ist nicht null")

        appLoggedInChange(true);
        appTokenChange(true);
      } else {
        appLoggedInChange(false);
        appTokenChange(false);
      }
    });

    checkServer().then((result) => {
      // // console.log("checktokenresult", result)
      if (result !== null) {
        // // console.log("token result ist nicht null")
        // console.log('result server',result)
        appServerChange(result);
      } else {
        appServerChange("https://web1.saint-online.de");
      }
    });
  });


  useEffect( () => {
   
    // console.log("useeffect event fired");

    checkToken().then((result) => {
      // // console.log("checktokenresult", result)
      if (result !== null) {
        // // console.log("token result ist nicht null")

        appLoggedInChange(true);
        appTokenChange(true);
      } else {
        appLoggedInChange(false);
        appTokenChange(false);
      }
    });

    checkServer().then((result) => {
      // // console.log("checktokenresult", result)
      if (result !== null) {
        // // console.log("token result ist nicht null")
        // console.log('result server',result)
        appServerChange(result);
      } else {
        appServerChange("https://web1.saint-online.de");
      }
    });

},[])

  return <></>;
};

export default AppData;
