import { IonCard, IonItem,IonCardContent, IonCardHeader, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
export default function Zusatzstoffe(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Zusatzstoffe & Allergene</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Zusatzstoffe & Allergene</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardHeader>
            <IonTitle>Zusatzstoffe</IonTitle>
          </IonCardHeader>
          <IonCardContent>
          <IonList>
          <IonItem>1 - Farbstoff </IonItem>
<IonItem>2 - Konservierung </IonItem>
<IonItem>3 - Antioxidantien </IonItem>
<IonItem>4 - Geschmacksv </IonItem>
<IonItem>5 - geschwefelt </IonItem>
<IonItem>6 - geschwärzt </IonItem>
<IonItem>7 - gewachst </IonItem>
<IonItem>8 - Phospaht </IonItem>
<IonItem>9 - Süßungsmittel </IonItem>
<IonItem>10 - Aspartam </IonItem>
<IonItem>11 - abführend  </IonItem>
<IonItem>12 - Schutzatmosphäre </IonItem>
<IonItem>13 - chininhaltig </IonItem>
<IonItem>14 - E Stoffe </IonItem>
<IonItem>15 - Nitr.Salz </IonItem>
<IonItem>16 - Nitrat </IonItem>
<IonItem>17 - koffein </IonItem>
<IonItem>18 - erhöter Koffeeingehalt </IonItem>
<IonItem>19 - alkoholähnliche Stoffe </IonItem>
<IonItem>20 - Stabilisatoren </IonItem>
<IonItem>21 - Nitritpöckelsalz </IonItem>
<IonItem>22 - Süßungsmittel </IonItem>
<IonItem>23 - enthält Koffeein </IonItem>
<IonItem>24 - Sulfite, Sulfate </IonItem>

          </IonList>


          </IonCardContent>
          </IonCard>
          <IonCard>
          <IonCardHeader>
            <IonTitle>Allergene</IonTitle>
          </IonCardHeader>
          <IonCardContent>
          <IonList>
          <IonItem>a - Gluten </IonItem>
<IonItem>a1 - Weizen </IonItem>
<IonItem>a2 - Roggen </IonItem>
<IonItem>a3 - Gerste </IonItem>
<IonItem>a4 - Hafer </IonItem>
<IonItem>a5 - Dinkel </IonItem>
<IonItem>a6 - Kamut </IonItem>
<IonItem>a7 - Grünkern </IonItem>
<IonItem>b - Krebs </IonItem>
<IonItem>c - Ei </IonItem>
<IonItem>d - Fisch </IonItem>
<IonItem>e - Erdnüsse </IonItem>
<IonItem>f - Soja </IonItem>
<IonItem>g - Milch </IonItem>
<IonItem>h - Spuren von Schalenfrüchten </IonItem>
<IonItem>h1 - Mandeln </IonItem>
<IonItem>h2 - Haselnüsse </IonItem>
<IonItem>h3 - Walnüsse </IonItem>
<IonItem>h4 - Kaschunüsse </IonItem>
<IonItem>h5 - Pecannüsse </IonItem>
<IonItem>h6 - Paranüsse </IonItem>
<IonItem>h7 - Pistazien </IonItem>
<IonItem>h8 - Macadamianüsse </IonItem>
<IonItem>i - Sellerie </IonItem>
<IonItem>j - Senf </IonItem>
<IonItem>k - Sesam </IonItem>
<IonItem>l - Schwefel </IonItem>
<IonItem>m - Lupinen </IonItem>
<IonItem>n - Weichtiere </IonItem>
<IonItem>o - Weichtiere </IonItem>
<IonItem>p - Schafsmilch </IonItem>
<IonItem>q - Alkohol </IonItem>
<IonItem>r - Ziegenmilch </IonItem>

          </IonList>


          </IonCardContent>
          </IonCard>
      </IonContent>
    </IonPage>
  );
};