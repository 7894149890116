import {
  IonContent,
  IonNote,
  IonInput,
  IonButton,
  IonList,
  IonIcon,
  IonLabel,
  IonItem,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonDatetime,
  IonPopover,
  IonDatetimeButton,
  IonModal,
} from "@ionic/react";
import "./Home.css";
import readerIcon from "../assets/reader.svg";
import { useContext } from "react";
import { DataContext } from "../context/DataContext";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Stationsbedarf() {
  const {
    appErfassung,
    appStationen,
    appStationChange,
    appStationenMenuesChange,
    appStationBezeichnung,
    appStationBezeichnungChange,
    appStationsbedarf,
    appStationsbedarf1,
    appStationsbedarf2,
    appStationsbedarf3,
    appStationsbedarf4,
    appStationsbedarf5,
    appStationsbedarf6,
    appStationsbedarf7,
    appStationsbedarfFilter,
    appStation,
    appServer,
    appToken,
    appStationsbedarfVorlauf,
    appStationsbedarfMinBestellDate
  } = useContext(DataContext);

  const [komponenten, setKomponenten] = useState([]);
  const [Bestellliste, setBestellliste] = useState();
  const [liefertag, setLiefertag] = useState();

  const isLiefertag = (dateString) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();
    
    /**
     * Date will be enabled if it is not
     * Sunday or Saturday
     */
    // console.log('utcDay',utcDay)
    
    let dayShow = [ appStationsbedarf7 , appStationsbedarf1 , appStationsbedarf2 , appStationsbedarf3 , appStationsbedarf4 , appStationsbedarf5 , appStationsbedarf6 ] ;

    // return utcDay !== 0 && utcDay !== 3 ;
    return dayShow[ utcDay ] ;
  }

  function GenerateBestellliste() {
    setBestellliste(
      appStationsbedarfFilter && [
        appStationsbedarfFilter.map((elementInArray) => (
          <IonItem slot="start">
              {elementInArray.rezepte_id}{" "}
              {elementInArray.aufgaben_positionen_bezeichnung} -{" "}
              {elementInArray.aufgaben_position_einheit}{" "}
            <IonItem slot="end">
            <IonInput
                disabled
                type="text"
                style={{ textAlign: "center" }}
                size="2"
                slot="start"
                value={
                  komponenten[elementInArray.rezepte_id]
                    ? komponenten[elementInArray.rezepte_id]
                    : 0
                }
              ></IonInput>
              <IonNote slot="end">
                {" "}
                <IonButton
                  size="large"
                  onClick={() =>
                    addKomponente(
                      elementInArray.rezepte_id,
                      komponenten[elementInArray.rezepte_id]
                        ? komponenten[elementInArray.rezepte_id] - 1
                        : 0
                    )
                  }
                >
                  -
                </IonButton>
              </IonNote>
              <IonNote slot="end">
              
</IonNote>
              
                <IonButton
                slot="end"
                  size="large"
                  onClick={() =>
                    addKomponente(
                      elementInArray.rezepte_id,
                      komponenten[elementInArray.rezepte_id] != null
                        ? komponenten[elementInArray.rezepte_id] + 1
                        : 1
                    )
                  }
                >
                  +
                </IonButton>
           
            </IonItem>
          </IonItem>
        )),
      ]
    );
  }

  function sendStationsanforderung(){
    // console.log('liefertag',liefertag)
    // console.log('wb',appStation)
    // console.log('artikel',komponenten)
    setLiefertag();

    const fetchData = {
      token: appToken,
      //BetriebeId: BetriebeId,
      data: {komponenten:komponenten,liefertag:liefertag}
    };
    const api = axios.create({
      baseURL:
      appServer +
        "/funktionen/api2/senioren/stationsbedarf_bestellen.php?wb="+appStation
    });
    api.post("", fetchData).then((res) => {
      let data = res.data;
      // console.log("daten", data);
    });




  }

  function addKomponente(rezept, newvalue) {
    let alt_komponenten = komponenten;
    alt_komponenten[rezept] = newvalue;
    setKomponenten(alt_komponenten);
    // console.log("alt_komponenten", alt_komponenten);
    // console.log("komponenten", komponenten);
    GenerateBestellliste();
    // SendData(bewohnerid,rezept,newvalue, 1, datumsql)
    // setSpeiseplanreturn(speiseplan())
  }

  useEffect(() => {
    GenerateBestellliste();
  }, []);

  useEffect(() => {
    // console.log("liefertag", liefertag);
  }, [liefertag]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Stationsbedarf {appStationBezeichnung}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">
              Stationsbedarf {appStationBezeichnung}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton routerLink={"/stationen/x/x/"}>zurück</IonButton>
        <IonItem>
          <IonLabel>Liefertag</IonLabel>
          <IonDatetime min={appStationsbedarfMinBestellDate} datetime="datetime"
          presentation="date"
          //value={liefertag}
          firstDayOfWeek={1}
          onIonChange={(e) => setLiefertag(e.target.value)}
          isDateEnabled={isLiefertag}
          >
          </IonDatetime>
    
          <IonModal keepContentsMounted={true}>
            <IonDatetime id="datetime"></IonDatetime>
          </IonModal>
          {/* <IonInput
            value={liefertag}
            onIonChange={(e) => setLiefertag(e.target.value)}
            min={appStationsbedarfMinBestellDate}
            type="date"
          ></IonInput> */}
        </IonItem>
        {liefertag != undefined ? <IonButton routerLink={"/stationen/x/x/"}  onClick={() => sendStationsanforderung()}>senden</IonButton> : <IonLabel color="warning">Bitte Liefertag wählen</IonLabel>}
        

        <IonList>{Bestellliste && Bestellliste}</IonList>
      </IonContent>
    </IonPage>
  );
}
