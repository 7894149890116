import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { defineCustomElements as jeepSqlite, applyPolyfills, JSX as LocalJSX  } from "jeep-sqlite/loader";
import { HTMLAttributes } from 'react';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';
type StencilToReact<T> = {
  [P in keyof T]?: T[P] & Omit<HTMLAttributes<Element>, 'className'> & {
    class?: string;
  };
} ;

declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> {
    }
  }
}

applyPolyfills().then(() => {
  jeepSqlite(window);
});

const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite)


window.addEventListener('DOMContentLoaded', async () => {
  const platform = Capacitor.getPlatform();
  
  try {
    if(platform === "web") {
      const jeepEl = document.createElement("jeep-sqlite");
      document.body.appendChild(jeepEl);
      await customElements.whenDefined('jeep-sqlite');
      await sqlite.initWebStore();
    }
    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection("saint_senioren")).result;
    var db: SQLiteDBConnection;

    if (ret.result && isConn) {
      db = await sqlite.retrieveConnection("saint_senioren");
    } else {
      db = await sqlite.createConnection("saint_senioren", false, "no-encryption", 1);
    }
    console.log('db connection',db)

    await db.open();
     let queryDrop = `
    DROP TABLE IF EXISTS saint_speiseplan;DROP TABLE IF EXISTS saint_rezepte;`;
 
    let queryCreate = `CREATE TABLE IF NOT EXISTS saint_speiseplan (
      speiseplan_id INTEGER PRIMARY KEY NOT NULL,
      speiseplan_text TEXT NOT NULL,
      rezepte_id INTEGER NOT NULL,
      betriebe_speiseplaene_zeit INTEGER NOT NULL,
      betriebe_speisepläne_vers INTEGER NOT NULL,
      speiseplan_tag DATE NOT NULL,
      speiseplan_kategorie INTEGER NOT NULL
    );
    `

   const resDrop: any = await db.execute(queryDrop);
    console.log(`resDrop: ${JSON.stringify(resDrop)}`); 
    const resCreate: any = await db.execute(queryCreate);
    
    let queryCreateRezepte = `CREATE TABLE IF NOT EXISTS saint_rezepte (
      rezepte_id INTEGER PRIMARY KEY NOT NULL,
      kcal VARCHAR NOT NULL,
      fett VARCHAR NOT NULL,
      ges_fetts VARCHAR NOT NULL,
      zucker VARCHAR NOT NULL
    );
    `

   /*  const resDrop: any = await db.execute(queryDrop);
    console.log(`resDrop: ${JSON.stringify(resDrop)}`); */
    const resCreateRezepte: any = await db.execute(queryCreateRezepte);

    let queryCreateVerzehr = `CREATE TABLE IF NOT EXISTS saint_verzehr (
      bewohner_verzehr_id INTEGER PRIMARY KEY NOT NULL,
      bewohner_verzehr_bewohner_id VARCHAR NOT NULL,
      bewohner_verzehr_rezept INTEGER NOT NULL,
      bewohner_verzehr_menge VARCHAR NOT NULL,
      saint_verzehr_menge_ist VARCHAR NOT NULL,
      bewohner_verzehr_tag DATE NOT NULL,
      bewohner_verzehr_zeit INTEGER NOT NULL
    );
    `

   /*  const resDrop: any = await db.execute(queryDrop);
    console.log(`resDrop: ${JSON.stringify(resDrop)}`); */
    const resCreateVerzehr: any = await db.execute(queryCreateVerzehr);

    console.log(`res: ${JSON.stringify(resCreate)}`);
    await db.close();
    await sqlite.closeConnection("saint_senioren");
    

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
  ,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    //serviceWorkerRegistration.unregister();
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    //reportWebVitals();

  } catch (err) {
    console.log(`Error: ${err}`);
    throw new Error(`Error: ${err}`)
  }

});